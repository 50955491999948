import Axios from 'axios';
import API_URL from '@/services/api';

const branchService = {
    getBranchList: params => {
        return Axios.get(`${API_URL.branch_list}`, {params});
    },
    getBranchDetail: branchID => {
        return Axios.get(`${API_URL.branch_parameter}/${branchID}`);
    },
    createBranch: data => {
        return Axios.post(`${API_URL.branch_list}`, data);
    },
    updateBranch: data => {
        return Axios.post(`${API_URL.branch_list}/${data.get('id')}`, data);
    },
    createBranchContact: data => {
        return Axios.post(`${API_URL.branch_contact}`, data);
    },
    updateBranchContact: data => {
        return Axios.post(`${API_URL.branch_contact}/${data.get('id')}`, data);
    },
    switchBranch: branchID => {
        return Axios.post(`${API_URL.branch_switch}/${branchID}`);
    },
};

export default branchService;
