<template>
  <div>
    <Sidebar />
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar';

export default {
  name: 'App',
  components: {
    Sidebar
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  margin-left: 125px;
  padding: 30px 30px 15px 30px;
  position: relative;
  min-height: 100vh;
  background: $white;
  min-width: 1170px;
}
// @media (max-width: 992px) {
//   .main-content {
//     min-width: 100%;
//   }
// }
</style>