import Axios from 'axios';
import API_URL from '@/services/api';

const staffService = {
  getLeaveList: (params = {}) => {
    return Axios.get(`${API_URL.leave_list}`, { params });
  },
  getLeaveParameter: () => {
    return Axios.get(`${API_URL.leave_parameter}`);
  },
  getStaffParameter: (params = {}) => {
    return Axios.get(`${API_URL.user_parameter}/staff`, { params });
  },
  createLeave: (data) => {
    return Axios.post(`${API_URL.leave_list}`, data);
  },
  deleteLeave: (leaveID) => {
    return Axios.delete(`${API_URL.leave_list}/${leaveID}`);
  },
  getStaffList: (params) => {
    return Axios.get(`${API_URL.user_list}/staff`, { params });
  },
  getStaffLeaveRemaining: (params) => {
    return Axios.get(`${API_URL.staff_list}`, { params });
  },
  getStaffDetail: (staffID) => {
    return Axios.get(`${API_URL.user_parameter}/staff/${staffID}`);
  },
  createStaff: (data) => {
    return Axios.post(`${API_URL.user_list}/staff`, data);
  },
  updateStaff: (data) => {
    return Axios.post(`${API_URL.user_list}/staff/${data.get('id')}`, data);
  },
  getStaffTourHistory: (params) => {
    return Axios.get(`${API_URL.user_parameter}/staff-history/${params.id}`, { params });
  },
  getAllStaffTourHistory: (params) => {
    return Axios.get(`${API_URL.user_parameter}/staff-history/${params.id}/all`, { params });
  },
  updateGuaranteeTour: (data) => {
    return Axios.post(`${API_URL.user_list}/staff-guarantee/${data.get('id')}`, data);
  },
  getStaffTourRoute: (params) => {
    return Axios.get(`${API_URL.user_parameter}/staff-route/${params.id}`);
  },
  updateStaffTourRoute: (data) => {
    return Axios.post(`${API_URL.user_list}/staff-tourroute/${data.get('id')}`, data);
  },
  updateStaffDayOff: (data) => {
    return Axios.post(`${API_URL.user_list}/staff-dayoff/${data.get('id')}`, data);
  },
  getMyStaffProfile: (params) => {
    return Axios.get(`${API_URL.user_parameter}/myprofile`, { params });
  },
  exportStaffLeave: (params) => {
    return Axios.get(API_URL.leave_report, { params }, { headers: { responseType: 'blob' } });
  },
  exportStaffReport: (params) => {
    return Axios.get(API_URL.staff_report, { params }, { headers: { responseType: 'blob' } });
  },
};

export default staffService;
