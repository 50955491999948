<template>
  <div v-if="bookingLogList && bookingLogList.length">
    <div class="row">
      <div class="col-6">
        <h5>Activity Logs ({{ countGroupedLogList }})</h5>
      </div>
      <div class="col-6 text-right">
        <a
          href="javascript:void(0)"
          @click="isShow = !isShow"
          class="link-underline"
        >{{ isShow ? 'Hide' : 'Show' }}</a>
      </div>
    </div>
    <div class="log-list" v-if="isShow">
      <div class="log" v-for="(log, key, index) in groupedLogList" :key="index">
        <div v-for="(data, logIndex) in log" :key="logIndex">
          <p class="text-muted mb-2" v-if="data.diff.message">{{ data.diff.message }}</p>
          <div v-if="!data.diff.message">
            <div class="mb-2" v-for="(diff, idx) in data.diff" :key="idx">
              <div v-if="data.model === 'booking'">
                <p>
                  <span class="mr-1 capitalize font-weight-bold">{{ diff.field }}:</span>
                  <span class="text-muted">from</span>
                  <span class="mx-1 line-through text-muted">{{ checkDate(diff.from) }}</span>
                  <span class="text-muted">to</span>
                  <span class="mx-1 text-muted">{{ checkDate(diff.to) }}</span>
                </p>
              </div>
              <div v-if="data.model === 'payment'">
                <p>
                  <span class="mr-1 capitalize font-weight-bold">{{ diff.type }}:</span>
                  <span class="text-muted">{{ diff.remark }} {{ diff.amount | number }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="m-0 mb-2" />
        <small class="text-muted">
          <span class="mr-2">{{ key | humanizeDateTime }}</span>
          <span
            class="text-muted"
            v-if="log[0] && log[0].user && log[0].user.staff_profile"
          >by {{ log[0].user.staff_profile.nickname || log[0].user.staff_profile.fullname }}</span>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      isShow: false,
      isShowChild: []
    };
  },
  props: {
    logList: {
      type: Array,
      default: () => [1, 2]
    }
  },
  computed: {
    ...mapState({
      bookingLogList: state => state.booking.bookingLogList
    }),
    groupedLogList() {
      const reformattedLogList = groupBy(this.bookingLogList, 'created_at');
      return reformattedLogList;
    },
    countGroupedLogList() {
      return Object.values(this.groupedLogList).length;
    }
  },
  methods: {
    showChild(index) {
      this.isShowChild = [];
      setTimeout(() => {
        this.isShowChild[index] = true;
      }, 1);
    },
    checkDate(logMessage) {
      if (moment(logMessage, 'YYYY-MM-DD', true).isValid()) {
        return moment(logMessage).format('DD/MM/YYYY');
      }

      return logMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
.log-list {
  margin-top: 15px;
  .log {
    padding: 15px;
    background: $light-grey;
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
    }
    svg {
      color: $dark-grey;
    }
  }
}
.line-through {
  text-decoration: line-through;
}
</style>