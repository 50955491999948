<template>
  <div>
    <h5>Extras</h5>
    <div class="people-count mt-4">
      <div class="row mt-2 mb-2" v-for="(sim, index) in customSimcardList" :key="index">
        <div class="col-6">
          <label>{{ sim.name }}</label>
        </div>
        <div class="col-6 text-right">
          <a href="javascript:void(0)" @click="removeSim(sim)" v-if="!isReadOnly">
            <font-awesome-icon icon="minus-circle"></font-awesome-icon>
          </a>
          <span class="count">{{ sim.phones.length }}</span>
          <a href="javascript:void(0)" @click="addSim(sim)" v-if="!isReadOnly">
            <font-awesome-icon icon="plus-circle"></font-awesome-icon>
          </a>
        </div>
        <div class="offset-1"></div>
        <div class="col-11">
          <div class="form-group row" v-for="(uniq, idx) in sim.phones" :key="idx">
            <label class="col-4 col-form-label no-wrap">Note</label>
            <div class="col-8">
              <input type="text" class="form-control" v-model="uniq.number" v-if="!isReadOnly" />
              <div v-if="isReadOnly" class="pt-2">{{ uniq.number }}</div>
            </div>
          </div>
        </div>
        <div v-for="(data, index) in invisibleList" :key="index"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      bookingSimcards: [],
      invisibleList: []
    };
  },
  props: {
    booking: {
      type: Object,
      default: () => null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      simcardList: state => state.simcard.simcardList,
      bookingDetail: state => state.booking.bookingDetail
    }),
    customSimcardList() {
      const data = [...this.simcardList];
      return data.map(d => {
        const mainSim = this.bookingSimcards.find(
          s => s.simcard_offer_id === d.id
        );
        return Object.assign(d, {
          phones: mainSim ? mainSim.phone_numbers : []
        });
      });
    }
  },
  created() {
    if (this.bookingDetail && this.bookingDetail.sim_cards) {
      this.bookingSimcards = [...this.bookingDetail.sim_cards];
    } else {
      this.bookingSimcards = [];
    }
  },
  methods: {
    addSim(sim) {
      sim.phones = [...sim.phones, { number: '' }];
      this.invisibleList.push({ name: 'hello' });
      this.$emit('addSim', sim);
    },
    removeSim(sim) {
      this.$emit('removePhone', sim.phones[sim.phones.length - 1]);
      setTimeout(() => {
        if (sim.phones.length === 0) {
          return;
        }
        sim.phones.pop();
        this.invisibleList.push({ name: 'hello' });
        this.$emit('removeSim', sim);
      }, 1);
    },
    clearData() {
      this.bookingSimcards = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.count {
  width: 45px;
  text-align: center;
  display: inline-block;
}
.people-count svg {
  color: $secondary;
}
</style>