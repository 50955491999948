const AGENT_ROUTING = [
  {
    path: 'list',
    name: 'agent-list',
    component: () => import('@/views/agents/AgentList.vue')
  },
  {
    path: ':id',
    name: 'agent-detail',
    component: () => import('@/views/agents/AgentDetail.vue'),
    children: [
      {
        path: 'profile',
        name: 'agent-user-profile',
        component: () => import('@/views/agents/AgentProfile.vue')
      },
      {
        path: 'bookings',
        name: 'agent-user-booking',
        component: () => import('@/views/agents/AgentBooking.vue')
      },
      {
        path: 'users',
        name: 'agent-user-users',
        component: () => import('@/views/agents/AgentUser.vue')
      }
    ]
  },
];

export default AGENT_ROUTING;
