import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import BootstrapVue from 'bootstrap-vue';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import VueLadda from 'vue-ladda';
import Axios from 'axios';
import VueQrcodeReader from 'vue-qrcode-reader';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import wysiwyg from 'vue-wysiwyg';
import Pusher from 'pusher-js';

// Custom JS
import './assets/js/font-awesome.js';
import './assets/js/pipes.js';
import './services/notificationService.js';

// Custom styling
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-datepicker/index.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import './assets/main.scss';

Vue.component('v-select', vSelect);
Vue.component('vue-ladda', VueLadda);
Vue.component('date-picker', DatePicker);
Vue.use(BootstrapVue);
Vue.use(VueQrcodeReader);
Vue.use(PerfectScrollbar);
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
  hideModules: {
    image: true,
    table: true,
    code: true,
    separator: true,
  },
});

Vue.prototype.$pusher = new Pusher('a8e50869d2ce659ff3e5', {
  cluster: 'ap1',
});

Vue.config.productionTip = false;

if (localStorage.jwtToken) {
  Axios.defaults.headers.common.authorization = `Bearer ${localStorage.jwtToken}`;
} else {
  delete Axios.defaults.headers.common.authorization;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// Clear console every time app got reloaded
window.addEventListener('message', (e) => {
  if (e.data && typeof e.data === 'string' && e.data.match(/webpackHotUpdate/)) {
    console.clear();
  }
});
