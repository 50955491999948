<template>
  <span>
    <span v-bind:class="checkClass(statusName)" v-if="!isSM">{{ statusName }}</span>
    <small v-bind:class="checkClass(statusName)" v-if="isSM">{{ statusName }}</small>
  </span>
</template>

<script>
export default {
  props: {
    statusName: String,
    isSM: Boolean
  },
  methods: {
    checkClass(statusName) {
      switch (statusName) {
        case 'Confirmed':
          return 'text-green';
        case 'Completed':
          return 'text-green';
        case 'Pending':
          return 'text-yellow';
        case 'Checked':
          return 'text-green';
        case 'Standby':
          return 'text-yellow';
        case 'Cancelled':
          return 'text-red';
        case 'No-show':
          return 'text-grey';
        default:
          return 'text-grey';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>