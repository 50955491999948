<template>
  <div>
    <button class="btn btn-outline-secondary w-100" @click="isShow = !isShow">
      <font-awesome-icon icon="minus" class="mr-2"></font-awesome-icon>Deduct
    </button>
    <b-modal v-model="isShow" :hide-footer="true" title="Deduct" centered>
      <div class="form-group row">
        <label class="col-form-label col-3">Amount</label>
        <div class="col-9">
          <div class="input-group no-border">
            <div class="input-group-prepend">
              <span class="input-group-text">THB</span>
            </div>
            <input
              type="number"
              class="form-control text-right"
              v-model="amount"
              @input="validateDeduct(amount)"
              :disabled="isFreeOfCharge"
            />
          </div>
          <small class="text-red" v-if="error.amount">Amount is required</small>
          <div class="mt-2 fa-pull-right" v-if="!isDisabledFOC">
            <Checkbox v-model="isFreeOfCharge" @input="selectFOC" /> Free of
            Charge
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3">Remark*</label>
        <div class="col-9">
          <input type="text" class="form-control" v-model="remark" />
          <small class="text-red" v-if="error.remark">Remark is required</small>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" @click="submit">Add</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import Checkbox from "@/components/commons/Checkbox";
import util from "@/services/utilService";

export default {
  data() {
    return {
      isShow: false,
      amount: "",
      remark: "",
      error: {},
      isFreeOfCharge: false,
    };
  },
  props: {
    totalNet: {
      type: Number,
      default: 0,
    },
    isDisabledFOC: {
      type: Boolean,
      default: false,
    },
    combineHistoryAndAmountList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Checkbox,
  },
  methods: {
    selectFOC() {
      if (this.isFreeOfCharge) {
        this.amount = this.totalNet.toFixed(2);
        this.$emit("updateFOC", true);
      } else {
        this.amount = 0;
        this.$emit("updateFOC", false);
      }
    },
    validateDeduct() {
      this.amount = util.validateDeduct(this.amount, this.totalNet);
    },
    validateInput: function () {
      this.error = {};
      if (!this.isFreeOfCharge) {
        this.amount === 0
          ? (this.error["amount"] = true)
          : delete this.error["amount"];
        this.remark
          ? delete this.error["remark"]
          : (this.error["remark"] = true);
      }
      return this.error;
    },
    submit: function () {
      if (!isEmpty(this.validateInput())) {
        return;
      }

      this.$emit("addDeduct", {
        type: this.isFreeOfCharge ? "FOC" : "Deduct",
        amount: this.isFreeOfCharge
          ? Number(this.totalNet * -1)
          : Number(this.amount * -1),
        remark: this.remark,
      });
      this.isShow = false;
      this.amount = "";
      this.remark = "";
      setTimeout(() => {
        this.$emit("totalNet", this.combineHistoryAndAmountList);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>