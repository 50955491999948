import Axios from 'axios';
import API_URL from '@/services/api';

const promotionService = {
  getPromotionList: params => {
    return Axios.get(`${API_URL.promocode}/list`, { params });
  },
  createPromotion: data => {
    return Axios.post(`${API_URL.promocode}/create`, data);
  },
  updatePromotion: data => {
    return Axios.post(`${API_URL.promocode}/${data.get('id')}`, data);
  },
  deletePromotion: id => {
    return Axios.delete(`${API_URL.promocode}/${id}`);
  },
  validatePromotion: params => {
    return Axios.get(`${API_URL.promocode}/validate`, { params });
  }
};

export default promotionService;
