import simcardService from '@/services/simcardService';

const simcard = {
  state: {
    simcardList: [],
  },
  mutations: {
    getSimcardList(state, simcardList) {
      state.simcardList = simcardList.data;
    },
    createSimcard(state, simcard) {
      state.simcardList = [...state.simcardList, simcard];
    },
    updateSimcard(state, simcard) {
      const index = state.simcardList.findIndex((sim) => sim.id === simcard.data.id);
      state.simcardList.splice(index, 1, simcard.data);
      state.simcardList = [...state.simcardList];
    },
    deleteSimcard(state, simID) {
      state.simcardList = state.simcardList.filter(sim => sim.id !== simID);
    },
  },
  actions: {
    async getSimcardList(context, params) {
      const res = await simcardService.getSimcardList(params);
      context.commit('getSimcardList', res.data);
    },
    async createSimcard(context, data) {
      const res = await simcardService.createSimcard(data);
      context.commit('createSimcard', res.data);
    },
    async updateSimcard(context, data) {
      const res = await simcardService.updateSimcard(data);
      context.commit('updateSimcard', res.data);
    },
    async deleteSimcard(context, id) {
      await simcardService.deleteSimcard(id);
      context.commit('deleteSimcard', id);
    },
  },
};

export default simcard;
