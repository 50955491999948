import Axios from 'axios';
import API_URL from '@/services/api';

const simcardService = {
  getSimcardList: (params = {}) => {
    return Axios.get(`${API_URL.simcard}/list`, { params });
  },
  createSimcard: (data) => {
    return Axios.post(`${API_URL.simcard}/create`, data);
  },
  updateSimcard: (data) => {
    return Axios.post(`${API_URL.simcard}/${data.get('id')}`, data);
  },
  deleteSimcard: (id) => {
    return Axios.delete(`${API_URL.simcard}/${id}`);
  }
};

export default simcardService;
