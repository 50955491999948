<template>
  <div>
    <div class="booking-status" v-bind:class="statusClass()">{{ statusName || '-' }}</div>
  </div>
</template>

<script>
export default {
  props: {
    statusName: String
  },
  methods: {
    statusClass() {
      switch (this.statusName) {
        case '฿.O':
          return 'green';
        case '฿.G':
          return 'green';
        case 'V':
          return 'green';
        case 'U':
          return 'red';
        case 'CV':
          return 'green';
        case 'CA':
          return 'green';
        case 'PP':
          return 'green';
        case 'BTC':
          return 'green';

        // New Gen
        case 'Unpaid':
          return 'red';
        case 'Partial':
          return 'yellow';
        case 'Refund-pending':
          return 'yellow';
        case 'Refund-Success':
          return 'green';
        case 'Free of Charge':
          return 'blue';
        case 'Free of Charge (Pending)':
          return 'blue';
        case 'Invoice Paid':
          return 'green';
        case 'IPD':
          return 'green';
        case 'Fully Paid':
          return 'green';
        case 'Prepaid':
          return 'green';

        default:
          return 'grey';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>