<template>
  <div>
    <b-modal v-model="isShow" :hide-footer="true" :title="title" centered>
      <p>{{ body }}</p>
      <div class="modal-footer flex-space-between">
        <a class="link-underline" href="javascript:void(0)" @click="isShow = false">Cancel</a>
        <vue-ladda
          @click="submit"
          button-class="btn btn-secondary ml-3"
          :loading="isLoading"
        >{{ button }}</vue-ladda>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      isLoading: false
    };
  },
  props: {
    id: String,
    title: {
      type: String,
      default: () => 'Confirmation'
    },
    body: {
      type: String,
      default: () => 'Are you sure you want to take this action?'
    },
    button: {
      type: String,
      default: () => 'Submit'
    }
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.$emit('submit', { id: this.id });
    },
    openModal() {
      this.isShow = true;
    },
    closeModal() {
      this.isLoading = false;
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>