import customerService from '@/services/customerService';

const customer = {
  state: {
    customerList: [],
    totalCustomer: 0
  },
  mutations: {
    getCustomerList(state, customerList) {
      state.customerList = customerList.data;
    },
    getTotalCustomer(state, total) {
      state.totalCustomer = total;
    }
  },
  actions: {
    async getCustomerList(context, params = {}) {
      await customerService.getCustomerList(params).then(res => {
        context.commit('getCustomerList', res.data);
        context.commit('getTotalCustomer', res.data.pagination ? res.data.pagination.total : 0);
      });
    },
    async updateCustomer(context, data) {
      await customerService.updateCustomer(data);
    }
  }
};

export default customer;
