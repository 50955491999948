import Axios from 'axios';
import API_URL from '@/services/api';

const adminService = {
  // User
  getUserList: (params = {}) => {
    return Axios.get(`${API_URL.user_list}`, { params });
  },
  getUserParameter: () => {
    return Axios.get(`${API_URL.user_parameter}`);
  },
  createUser: (data = {}) => {
    return Axios.post(`${API_URL.user_list}`, data);
  },
  updateUser: (data = {}) => {
    return Axios.post(`${API_URL.user_list}/${data.id}`, data.userData);
  },
  deleteUser: userID => {
    return Axios.delete(`${API_URL.user_list}/${userID}`);
  },
  // Department
  getDepartmentList: (params = {}) => {
    return Axios.get(`${API_URL.department_list}`, { params });
  },
  createDepartment: (data = {}) => {
    return Axios.post(`${API_URL.department_list}`, data);
  },
  updateDepartment: (data = {}) => {
    return Axios.post(`${API_URL.department_list}/${data.id}`, data.departmentData);
  },
  deleteDepartment: departmentID => {
    return Axios.delete(`${API_URL.department_list}/${departmentID}`);
  },
  // Email
  getEmailList: (params = {}) => {
    return Axios.get(`${API_URL.email_list}`, { params });
  },
  getEmailDetail: templateTypeID => {
    return Axios.get(`${API_URL.email_parameter}/${templateTypeID}`);
  },
  updateEmail: data => {
    return Axios.post(`${API_URL.email_list}/${data.get('id')}`, data);
  },
  // Country
  getCountryList: params => {
    return Axios.get(`${API_URL.country_list}`, { params });
  },
  getCountryDetail: countryID => {
    return Axios.get(`${API_URL.country_parameter}/${countryID}`);
  },
  createCountry: data => {
    return Axios.post(`${API_URL.country_list}`, data);
  },
  updateCountry: data => {
    return Axios.post(`${API_URL.country_list}/${data.get('id')}`, data);
  },
  deleteCountry: countryID => {
    return Axios.delete(`${API_URL.country_list}/${countryID}`);
  },
  // Location
  getLocationList: params => {
    return Axios.get(`${API_URL.location_list}`, { params });
  },
  getLocationDetail: locationID => {
    return Axios.get(`${API_URL.location_parameter}/${locationID}`);
  },
  createLocation: data => {
    return Axios.post(`${API_URL.location_list}`, data);
  },
  updateLocation: data => {
    return Axios.post(`${API_URL.location_list}/${data.get('id')}`, data);
  },
  deleteLocation: locationID => {
    return Axios.delete(`${API_URL.location_list}/${locationID}`);
  }
};

export default adminService;
