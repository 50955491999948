const STAFF_ROUTING = [
  {
    path: 'calendar',
    name: 'staff-calendar',
    component: () => import('@/views/staffs/StaffCalendar.vue')
  },
  {
    path: 'list',
    name: 'staff-list',
    component: () => import('@/views/staffs/StaffList.vue')
  }
];

export default STAFF_ROUTING;
