import moment from 'moment';
import staffService from '@/services/staffService';

const staff = {
  state: {
    staffList: [],
    staffRemainingList: [],
    staffDetail: {},
    leaveList: [],
    todayLeaveList: [],
    calendarList: [],
    parameterList: [],
    staffParameterList: [],
    isLoadingLeaveList: false,
    tourHistoryList: [],
    allTourHistoryList: [],
    tourRouteList: [],
    myStaffProfile: {}
  },
  mutations: {
    getLeaveList(state, leaveList) {
      state.leaveList = leaveList.data;
      state.calendarList = state.leaveList.map(obj => {
        return {
          title: obj.leave_type_name + ' - ' + obj.fullname,
          start: obj.start_date,
          end: obj.end_date ? moment(obj.end_date)
            .add(1, 'days')
            .format('YYYY-MM-DD') : obj.start_date,
          backgroundColor: obj.color_type,
          borderColor: obj.color_type
        };
      });
    },
    getTodayLeaveList(state, leaveList) {
      state.todayLeaveList = leaveList.data;
    },
    createLeave(state, newLeave) {
      state.leaveList = [newLeave.data, ...state.leaveList];
      state.calendarList = state.leaveList.map(obj => {
        return {
          title: obj.leave_type_name + ' - ' + obj.fullname,
          start: obj.start_date,
          end: obj.end_date ? moment(obj.end_date)
            .add(1, 'days')
            .format('YYYY-MM-DD') : obj.start_date,
          backgroundColor: obj.color_type,
          borderColor: obj.color_type
        };
      });
    },
    deleteLeave(state, leaveID) {
      console.log('leaveID', leaveID);
    },
    getLeaveParameter(state, parameterList) {
      state.parameterList = parameterList.data;
    },
    getStaffParameter(state, parameterList) {
      state.staffParameterList = parameterList.data;
    },
    setLoadingLeaveList(state, isLoading) {
      state.isLoadingLeaveList = isLoading;
    },
    getStaffList(state, staffList) {
      state.staffList = staffList.data;
    },
    getStaffLeaveRemaining(state, staffList) {
      state.staffRemainingList = staffList.data;
    },
    getStaffDetail(state, staff) {
      state.staffDetail = staff.data;
    },
    getStaffTourHistory(state, historyList) {
      state.tourHistoryList = historyList.data;
    },
    getAllStaffTourHistory(state, historyList) {
      state.allTourHistoryList = historyList.data;
    },
    createStaff(state, newStaff) {
      state.staffList = [...state.staffList, newStaff.data];
    },
    getStaffTourRoute(state, tourRouteList) {
      state.tourRouteList = tourRouteList.data;
    },
    getMyStaffProfile(state, profile) {
      state.myStaffProfile = profile.data;
    }
  },
  actions: {
    async getLeaveList(context, params = {}) {
      context.commit('setLoadingLeaveList', true);
      const res = await staffService.getLeaveList(params);
      context.commit('getLeaveList', res.data);
      context.commit('setLoadingLeaveList', false);
    },
    async getTodayLeaveList(context, params = {}) {
      const res = await staffService.getLeaveList(params);
      context.commit('getTodayLeaveList', res.data);
    },
    async getLeaveParameter(context, params = {}) {
      const res = await staffService.getLeaveParameter(params);
      context.commit('getLeaveParameter', res.data);
    },
    async getStaffParameter(context, params = {}) {
      const res = await staffService.getStaffParameter(params);
      context.commit('getStaffParameter', res.data);
    },
    async createLeave(context, data) {
      const res = await staffService.createLeave(data);
      context.commit('createLeave', res.data);
    },
    async deleteLeave(context, leaveID) {
      await staffService.deleteLeave(leaveID);
      context.commit('deleteLeave', leaveID);
    },
    async getStaffList(context, params) {
      const res = await staffService.getStaffList(params);
      context.commit('getStaffList', res.data);
    },
    async getStaffLeaveRemaining(context, params) {
      const res = await staffService.getStaffLeaveRemaining(params);
      context.commit('getStaffLeaveRemaining', res.data);
    },
    async getStaffDetail(context, staffID) {
      const res = await staffService.getStaffDetail(staffID);
      context.commit('getStaffDetail', res.data);
    },
    async createStaff(context, data) {
      const res = await staffService.createStaff(data);
      context.commit('createStaff', res.data);
    },
    async updateStaff(context, data) {
      await staffService.updateStaff(data);
    },
    async getStaffTourHistory(context, params) {
      const res = await staffService.getStaffTourHistory(params);
      context.commit('getStaffTourHistory', res.data);
    },
    async getAllStaffTourHistory(context, params) {
      const res = await staffService.getAllStaffTourHistory(params);
      context.commit('getAllStaffTourHistory', res.data);
    },
    async updateGuaranteeTour(context, params) {
      await staffService.updateGuaranteeTour(params);
    },
    async getStaffTourRoute(context, params) {
      const res = await staffService.getStaffTourRoute(params);
      context.commit('getStaffTourRoute', res.data);
    },
    async updateStaffTourRoute(context, data) {
      await staffService.updateStaffTourRoute(data);
    },
    async updateStaffDayOff(context, data) {
      await staffService.updateStaffDayOff(data);
    },
    async getMyStaffProfile(context, data) {
      const res = await staffService.getMyStaffProfile(data);
      context.commit('getMyStaffProfile', res.data);
    },
    async exportStaffReport(context, params) {
      await staffService.exportStaffReport(params).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'text/csv',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', params.name + '.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
    async exportStaffLeave(context, params) {
      await staffService.exportStaffLeave(params).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'text/csv',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', params.name + '.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
  }
};

export default staff;
