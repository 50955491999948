import { library } from '@fortawesome/fontawesome-svg-core';
import Vue from 'vue';
import {
  faHome,
  faInfoCircle,
  faPhoneSquareAlt,
  faBicycle,
  faFlag,
  faAddressCard,
  faHandshake,
  faUser,
  faArchive,
  faShareAlt,
  faCog,
  faCalendarCheck,
  faChevronLeft,
  faPlus,
  faPlusCircle,
  faDownload,
  faPencilAlt,
  faFileUpload,
  faSearch,
  faCircleNotch,
  faCircle,
  faChevronRight,
  faEllipsisV,
  faMinusCircle,
  faMinus,
  faChevronUp,
  faChevronDown,
  faUserFriends,
  faTimesCircle,
  faCheckSquare,
  faSquare,
  faSpinner,
  faStar,
  faInfinity,
  faMapMarkerAlt,
  faEnvelopeOpen,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faHome,
  faInfoCircle,
  faPhoneSquareAlt,
  faBicycle,
  faFlag,
  faAddressCard,
  faHandshake,
  faUser,
  faArchive,
  faShareAlt,
  faCog,
  faCalendarCheck,
  faChevronLeft,
  faPlus,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faDownload,
  faFileUpload,
  faPencilAlt,
  faSearch,
  faCircleNotch,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faEllipsisV,
  faChevronUp,
  faChevronDown,
  faUserFriends,
  faTimesCircle,
  faCheckSquare,
  faSquare,
  faSpinner,
  faStar,
  faInfinity,
  faMapMarkerAlt,
  faEnvelopeOpen,
  faGlobe
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
