import Axios from 'axios';
import API_URL from '@/services/api';

const bookingService = {
  getBookingList: (params = {}) => {
    return Axios.get(`${API_URL.booking_list}`, { params });
  },
  getBookingParameter: (params) => {
    return Axios.get(`${API_URL.booking_parameter}`, { params });
  },
  getBookingDetail: (bookingID) => {
    return Axios.get(`${API_URL.booking_parameter}/${bookingID}`);
  },
  createBooking: (data) => {
    return Axios.post(`${API_URL.booking_list}`, data);
  },
  updateBooking: (data) => {
    return Axios.post(`${API_URL.booking_list}/${data.get('bookid')}`, data);
  },
  deleteBooking: (bookingID) => {
    return Axios.delete(`${API_URL.booking_list}/${bookingID}`);
  },
  checkBookingAvailability: (data) => {
    return Axios.post(`${API_URL.booking_available}`, data);
  },
  getBookingSourceList: (params) => {
    return Axios.get(`${API_URL.booking_source}`, { params });
  },
  // Special booking
  createSpecialBooking: (data) => {
    return Axios.post(`${API_URL.booking_list}/special`, data);
  },
  // Monthly Booking
  getMonthlyBooking: (params) => {
    return Axios.get(`${API_URL.booking_monthly}`, { params });
  },
  // FOC
  updateFOC: (data) => {
    return Axios.post(`${API_URL.booking_list}/foc/${data.get('bookid')}`, data);
  },
  //
  getBookingLog: (bookid) => {
    return Axios.get(`${API_URL.booking}/activity/${bookid}`);
  },
  // Report
  generateBookingReport: (params) => {
    const exportPath = params.type === 'log' ? 'report-log' : 'report';
    return Axios.get(
      `${API_URL.booking_list}/${exportPath}`,
      { params },
      { headers: { 'responseType': 'blob' } }
    );
  }
};

export default bookingService;
