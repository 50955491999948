const USER_ROLE = [
  {
    role: 'master',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: true,
      promocodes: true,
      branches: true,
      admin: true,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'country-superadmin',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: true,
      promocodes: true,
      branches: true,
      admin: true,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'branch-superadmin',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: true,
      promocodes: true,
      branches: true,
      admin: true,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'branch-admin',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: true,
      promocodes: true,
      branches: true,
      admin: true,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-marketing',
    landing_page: '/agents/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: false,
      tours: true,
      staffs: false,
      agents: true,
      customers: true,
      products: false,
      promocodes: false,
      branches: true,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-guide',
    landing_page: '/staffs/calendar',
    menu: {
      bookings: false,
      check_in: false,
      guides: false,
      tours: false,
      staffs: true,
      agents: false,
      customers: false,
      products: false,
      promocodes: false,
      branches: false,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-finance',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: false,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: false,
      promocodes: false,
      branches: true,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-booking',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: false,
      tours: true,
      staffs: false,
      agents: true,
      customers: true,
      products: false,
      promocodes: false,
      branches: true,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-checkin',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: false,
      tours: true,
      staffs: false,
      agents: false,
      customers: true,
      products: false,
      promocodes: false,
      branches: false,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'staff-hr',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: false,
      customers: true,
      products: false,
      promocodes: false,
      branches: false,
      admin: false,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
  {
    role: 'agent-owner',
    landing_page: '/agentBooking',
    menu: {
      bookings: false,
      check_in: false,
      guides: false,
      tours: false,
      staffs: false,
      agents: false,
      customers: false,
      products: false,
      promocodes: false,
      branches: false,
      admin: false,
      agentBooking: true,
      agentProfile: true,
      myProfile: false
    }
  },
  {
    role: 'agent-employee',
    landing_page: '/agentBooking',
    menu: {
      bookings: false,
      check_in: false,
      guides: false,
      tours: false,
      staffs: false,
      agents: false,
      customers: false,
      products: false,
      promocodes: false,
      branches: false,
      admin: false,
      agentBooking: true,
      agentProfile: true,
      myProfile: false
    }
  },
  {
    role: 'admin',
    landing_page: '/bookings/list',
    menu: {
      bookings: true,
      check_in: true,
      guides: true,
      tours: true,
      staffs: true,
      agents: true,
      customers: true,
      products: true,
      promocodes: false,
      branches: false,
      admin: true,
      agentBooking: false,
      agentProfile: false,
      myProfile: true
    }
  },
];

export default USER_ROLE;
