import adminService from '@/services/adminService';

const admin = {
  state: {
    userList: [],
    parameterList: [],
    departmentList: [],
    ownBranch: [],
    emailList: [],
    emailDetail: null,
    isLoadingUser: true,
    isLoadingDepartment: true,
    countryList: [],
    locationList: [],
  },
  mutations: {
    getUserList(state, userList) {
      state.userList = userList.data;
      state.isLoadingUser = false;
    },
    getOwnBranch(state, branch) {
      state.ownBranch = branch.own_branch;
    },
    getUserParameter(state, parameterList) {
      state.parameterList = parameterList.data;
    },
    createUser(state, newUser) {
      state.userList = [newUser.data, ...state.userList];
    },
    updateUser(state, updatedUser) {
      const userIndex = state.userList.findIndex((user) => user.id === updatedUser.data.id);
      const cloneUserList = Object.assign([], state.userList);
      cloneUserList[userIndex] = updatedUser.data;
      state.userList = cloneUserList;
    },
    deleteUser(state, userID) {
      state.userList = state.userList.filter((user) => user.id !== userID);
    },
    // Department
    getDepartmentList(state, departmentList) {
      state.departmentList = departmentList.data;
      state.isLoadingDepartment = false;
    },
    createDepartment(state, newDepartment) {
      state.departmentList = [...state.departmentList, newDepartment.data];
    },
    updateDepartment(state, updatedDepartment) {
      const departmentIndex = state.departmentList.findIndex(
        (department) => department.id === updatedDepartment.data.id
      );
      const cloneDepartmentList = Object.assign([], state.departmentList);
      cloneDepartmentList[departmentIndex] = updatedDepartment.data;
      state.departmentList = cloneDepartmentList;
    },
    deleteDepartment(state, departmentID) {
      state.departmentList = state.departmentList.filter(
        (department) => department.id !== departmentID
      );
    },
    setUserLoadingSpinner(state) {
      state.isLoadingUser = true;
    },
    setDepartmentLoadingSpinner(state) {
      state.isLoadingDepartment = true;
    },
    // Email
    getEmailList(state, emailList) {
      state.emailList = emailList.data;
    },
    getEmailDetail(state, email) {
      state.emailDetail = email.data;
    },
    // Country
    getCountryList(state, countryList) {
      state.countryList = countryList.data;
    },
    getLocationList(state, locationList) {
      state.locationList = locationList.data;
    },
    deleteLocation(state, locationID) {
      state.locationList = state.locationList.filter((location) => location.id !== locationID);
    },
  },
  actions: {
    async getUserList(context, params = {}) {
      const res = await adminService.getUserList(params);
      context.commit('getUserList', res.data);
      context.commit('getOwnBranch', res.data);
    },
    async getUserParameter(context, params = {}) {
      const res = await adminService.getUserParameter(params);
      context.commit('getUserParameter', res.data);
    },
    async createUser(context, data = {}) {
      const res = await adminService.createUser(data);
      context.commit('createUser', res.data);
    },
    async updateUser(context, data = {}) {
      const res = await adminService.updateUser(data);
      context.commit('updateUser', res.data);
    },
    async deleteUser(context, userID) {
      await adminService.deleteUser(userID);
      context.commit('deleteUser', userID);
    },
    // Department
    async getDepartmentList(context, params = {}) {
      const res = await adminService.getDepartmentList(params);
      context.commit('getDepartmentList', res.data);
    },
    async createDepartment(context, data = {}) {
      const res = await adminService.createDepartment(data);
      context.commit('createDepartment', res.data);
    },
    async updateDepartment(context, data = {}) {
      const res = await adminService.updateDepartment(data);
      context.commit('updateDepartment', res.data);
    },
    async deleteDepartment(context, departmentID) {
      await adminService.deleteDepartment(departmentID);
      context.commit('deleteDepartment', departmentID);
      context.dispatch('getUserList');
    },
    // Email
    async getEmailList(context, params = {}) {
      const res = await adminService.getEmailList(params);
      context.commit('getEmailList', res.data);
    },
    async getEmailDetail(context, templateTypeID) {
      const res = await adminService.getEmailDetail(templateTypeID);
      context.commit('getEmailDetail', res.data);
    },
    async updateEmail(context, data) {
      await adminService.updateEmail(data);
    },
    // Country
    async getCountryList(context, params = {}) {
      const res = await adminService.getCountryList(params);
      context.commit('getCountryList', res.data);
    },
    async createCountry(context, data) {
      await adminService.createCountry(data);
    },
    async updateCountry(context, data) {
      await adminService.updateCountry(data);
    },
    async deleteCountry(context, countryID) {
      await adminService.deleteCountry(countryID);
    },
    // Location
    async getLocationList(context, params = {}) {
      const res = await adminService.getLocationList(params);
      context.commit('getLocationList', res.data);
    },
    async createLocation(context, data) {
      await adminService.createLocation(data);
    },
    async updateLocation(context, data) {
      await adminService.updateLocation(data);
    },
    async deleteLocation(context, locationID) {
      await adminService.deleteLocation(locationID);
      context.commit('deleteLocation', locationID);
    },
  },
};

export default admin;
