<template>
  <table class="table summary-price-table">
    <tbody>
      <tr>
        <td width="70%">Price</td>
        <td width="5%">THB</td>
        <td width="25%" class="text-right">
          <span class="h5 text-black">{{ total_amount | number }}</span>
        </td>
      </tr>
      <tr
        v-for="(ext, index) in combineHistoryAndAmountList"
        :key="index"
        v-bind:class="{
          'd-none':
            (ext.type === 'Changes' && calculatePax(ext)) ||
            (ext.type === 'Sim' && ext.amount === 0) ||
            ext.type === 'bill',
          excluded:
            ext.type === 'change' ||
            (ext.type === 'Changes' && !calculatePax(ext)),
          refund: ext.type === 'refund',
        }"
      >
        <td>
          <span style="text-transform: capitalize">{{ ext.type }}</span>
          <p class="mb-0" v-if="ext.type !== 'bill'">
            &raquo; {{ ext.remark }}
          </p>
        </td>
        <td>THB</td>
        <td class="text-right no-wrap">
          <span class="h5 text-black">
            {{ ext.amount | number }}
          </span>
        </td>
      </tr>
      <tr class="total-payment" v-if="combineHistoryAndAmountList.length">
        <td>Balance</td>
        <td>THB</td>
        <td class="text-right">
          <span class="h5 text-black">
            <span
              id="priceSummeryAmount"
              v-bind:class="{
                'text-red': total_net > 0,
                'text-green': total_net < 0,
              }"
              >{{ reverse_total_net | number }}</span
            >
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    combineHistoryAndAmountList: {
      type: Array,
      default: () => [],
    },
    tour: {
      type: Object,
      default: () => null,
    },
    total_net: {
      type: Number,
      default: 0,
    },
    total_amount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    reverse_total_net() {
      return -1 * this.total_net;
    },
  },
  methods: {
    calculatePax(ext) {
      const extraPrice =
        ext.ad * this.tour.adult_price +
        ext.ch * this.tour.children_price +
        ext.cs * this.tour.childrenseat_price +
        ext.bs * this.tour.babyseat_price;
      return extraPrice === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-price-table {
  td {
    color: #a7a7a7;
  }
  .total-payment {
    background: $light-grey;
  }
}
.excluded {
  background-color: #ffffe5 !important;
  display: none;
}
.refund {
  background-color: #ffe5e5 !important;
}
</style>