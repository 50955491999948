const BASE_URL = process.env.VUE_APP_API_URL;
const API_VERSION = 'v1';
const BASE_API_URL = `${BASE_URL}/api/${API_VERSION}`;

// Order alphabetically
const API_URL = {
  agent_list: `${BASE_API_URL}/agent-list`,
  alltour_list: `${BASE_API_URL}/alltour-list`,
  alltour_parameter: `${BASE_API_URL}/alltour-parameters`,
  booking_available: `${BASE_API_URL}/book-available`,
  booking: `${BASE_API_URL}/booking`,
  booking_list: `${BASE_API_URL}/book-list`,
  booking_monthly: `${BASE_API_URL}/booking/monthly`,
  booking_parameter: `${BASE_API_URL}/book-parameters`,
  booking_source: `${BASE_API_URL}/bookingsource-list`,
  branch_contact: `${BASE_API_URL}/branch-contact`,
  branch_list: `${BASE_API_URL}/branch-list`,
  branch_parameter: `${BASE_API_URL}/branch-parameters`,
  branch_switch: `${BASE_API_URL}/branch-switch`,
  check_email: `${BASE_API_URL}/check-email`,
  checkin_list: `${BASE_API_URL}/checkin-list`,
  country_list: `${BASE_API_URL}/country-list`,
  country_parameter: `${BASE_API_URL}/country-parameters`,
  customer_list: `${BASE_API_URL}/customer-list`,
  create: `${BASE_API_URL}/create`,
  department_list: `${BASE_API_URL}/department-list`,
  detail: `${BASE_API_URL}/detail`,
  email_list: `${BASE_API_URL}/email-list`,
  email_parameter: `${BASE_API_URL}/email-parameters`,
  guide_list: `${BASE_API_URL}/guide-list`,
  guide_parameters: `${BASE_API_URL}/guide-parameters`,
  group_color_list: `${BASE_API_URL}/groupcolour-list`,
  group_color_parameters: `${BASE_API_URL}/groupcolour-parameters`,
  leave_history: `${BASE_API_URL}/leave-history`,
  leave_list: `${BASE_API_URL}/leave-list`,
  leave_report: `${BASE_API_URL}/report/leaves`,
  leave_parameter: `${BASE_API_URL}/leave-parameters`,
  location_list: `${BASE_API_URL}/location-list`,
  location_parameter: `${BASE_API_URL}/location-parameters`,
  login: `${BASE_API_URL}/login`,
  logout: `${BASE_API_URL}/logout`,
  promocode: `${BASE_API_URL}/promocode`,
  simcard: `${BASE_API_URL}/sim`,
  staff_list: `${BASE_API_URL}/staff-list`,
  staff_report: `${BASE_API_URL}/report/staffs`,
  tour:  `${BASE_API_URL}/tour`,
  tour_list: `${BASE_API_URL}/tour-list`,
  tour_parameter: `${BASE_API_URL}/tour-parameters`,
  tour_summary: `${BASE_API_URL}/tour-summary`,
  user_list: `${BASE_API_URL}/user-list`,
  user_parameter: `${BASE_API_URL}/user-parameters`
};

export default API_URL;
