import Vue from 'vue';
import Vuex from 'vuex';
import agent from '@/store/modules/agent';
import admin from '@/store/modules/admin';
import booking from '@/store/modules/booking';
import customer from '@/store/modules/customer';
import staff from '@/store/modules/staff';
import tour from '@/store/modules/tour';
import guide from '@/store/modules/guide';
import branch from '@/store/modules/branch';
import promotion from '@/store/modules/promotion';
import simcard from '@/store/modules/simcard';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    agent: agent,
    admin: admin,
    booking: booking,
    branch: branch,
    customer: customer,
    promotion: promotion,
    simcard: simcard,
    staff: staff,
    tour: tour,
    guide: guide
  }
});
