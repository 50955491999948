import Axios from 'axios';
import API_URL from '@/services/api';

const customerService = {
  getCustomerList: (params = {}) => {
    return Axios.get(`${API_URL.customer_list}`, { params });
  },
  updateCustomer: data => {
    return Axios.post(`${API_URL.customer_list}/${data.get('email')}`, data);
  }
};

export default customerService;
