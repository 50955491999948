import guideService from '@/services/guideService';

const guide = {
  state: {
    guideList: [],
    guideTeamList: [],
    standbyGuideList: [],
    isLoadingGuide: false,
    isLoadingGuideTeam: false
  },
  mutations: {
    getGuideList(state, guideList) {
      state.guideList = guideList.data;
    },
    getGuideTeamList(state, guideTeamList) {
      state.guideTeamList = guideTeamList.data;
    },
    getStandbyGuideList(state, standbyList) {
      state.standbyGuideList = standbyList.data;
    },
    setGuideLoadingSpinner(state, isLoading) {
      state.isLoadingGuide = isLoading;
    },
    setGuideTeamLoadingSpinner(state, isLoading) {
      state.isLoadingGuideTeam = isLoading;
    },
    clearGuideTeamList(state) {
      state.guideTeamList = [];
    }
  },
  actions: {
    async getGuideList(context, params = {}) {
      context.commit('setGuideLoadingSpinner', true);
      await guideService.getGuideList(params).then(res => {
        context.commit('getGuideList', res.data);
        context.commit('setGuideLoadingSpinner', false);
      });
    },
    async getGuideTeamList(context, params = {}) {
      context.commit('setGuideTeamLoadingSpinner', true);
      await guideService.getGuideTeamList(params).then(res => {
        context.commit('getGuideTeamList', res.data);
        context.commit('setGuideTeamLoadingSpinner', false);
      });
    },
    async getStandbyGuideList(context, params = {}) {
      await guideService.getStandbyGuideList(params).then(res => {
        context.commit('getStandbyGuideList', res.data);
      });
    },
    async createGuideTeam(context, data = {}) {
      await guideService.createGuideTeam(data).then(() => {
        context.commit('setGuideLoadingSpinner', false);
      });
    },
    async updateGuideTeam(context, data = {}) {
      await guideService.updateGuideTeam(data).then(() => {
        context.commit('setGuideLoadingSpinner', false);
      });
    },
    async deleteGuideTeam(context, data = {}) {
      await guideService.deleteGuideTeam(data);
    },
    async updateGuideColorTeam(context, data = {}) {
      await guideService.updateGuideColorTeam(data)
    }
  }
};

export default guide;
