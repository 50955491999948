import * as moment from 'moment';
import Vue from 'vue';

Vue.filter('humanizeDate', (value) => {
  return value ? moment(value).format('DD/MM/YYYY') : '';
});

Vue.filter('humanizeDateTime', (value) => {
  return value ? moment(value).format('DD/MM/YYYY HH:mm') : '';
});

Vue.filter('humanizeTime', (value) => {
  return value ? moment(value).format('HH:mm') : '';
});

Vue.filter('fullHumanizeDate', (value) => {
  return value ? moment(value).format('ddd, DD MMM YYYY') : '';
});

Vue.filter('monthAbbreviation', (value) => {
  return value ? moment(value).format('MMM') : '';
});


Vue.filter('number', (value) => {
  return value
    ? parseFloat(value.toString()).toLocaleString('en-US', { minimumFractionDigits: 2 })
    : value;
});

Vue.filter('timeType', (value) => {
  return value ? value.charAt(0) : value;
});

Vue.filter('excludeTourName', (value) => {
  return value ? value.replace('Tours', '').replace('Tour', '') : value;
});

Vue.filter('assistant', (value) => {
  return value === 'assist' ? 'Assistant' : value;
})

Vue.filter('abs', (value) => {
  return Math.abs(value);
})

Vue.filter('dateRange', (value) => {
  const dayNum = value;
  const year = Math.floor(dayNum / 365);
  const month = Math.floor((dayNum % 365) / 30);
  const day = Math.floor(dayNum % 365 % 30);
  return value ? `${year} Years, ${month} Months, ${day} Days` : value;
})