const GUIDE_ROUTING = [
  {
    path: 'booking',
    name: 'guide-booking',
    component: () => import('@/views/guides/GuideBooking.vue')
  },
  {
    path: 'tour',
    name: 'guide-tour',
    component: () => import('@/views/guides/GuideTour.vue')
  },
];

export default GUIDE_ROUTING;
