<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';

export default {
  watch: {
    $route() {
      if (this.$route.path !== '/login')
        axios.get(process.env.VUE_APP_API_URL + '/api/v1/token').catch(err => {
          if (err?.response?.status === 401) {
            this.$router.replace({ path: "/login" });
          }
        })
    }
  },
  created() {
    $(document).ready(function () {
      $(document).on('focus', ':input', function () {
        $(this).attr('autocomplete', 'new-password');
      });
    });
  }
};
</script>

<style lang="scss" scoped></style>