import moment from 'moment';
import bookingService from '@/services/bookingService';
import tour from '../modules/tour';

const booking = {
  state: {
    bookingList: [],
    totalBooking: 0,
    bookingDetail: {},
    searchParams: {},
    parameterList: [],
    isLoadingBooking: true,
    isLoadingBookingDetail: true,
    isLoadingTour: true,
    currentDate: moment().format('YYYY-MM-DD'),
    pendingBookingCount: 0,
    bookingAvailability: true,
    bookingAvailabilityMsg: 'Available',
    isLoadingAvailability: false,
    monthlyBooking: null,
    bookingLogList: [],
    highlightBooking: null,
    scannedBooking: null,
    bookingSourceList: [],
    isSearchBookingPriority: false
  },
  mutations: {
    getBookingList(state, bookingList) {
      state.bookingList = bookingList.data;
    },
    getBookingParameter(state, parameterList) {
      state.parameterList = parameterList.data;
    },
    getBookingDetail(state, booking) {
      state.bookingDetail = booking.data;
    },
    createBooking(state, newBooking) {
      const tourID = tour.state.tourID;
      const isFromSameTourGroup = Number(newBooking.data.tourgroup_id) === Number(tourID);
      if (isFromSameTourGroup) {
        state.bookingList.book = [newBooking.data, ...state.bookingList.book];
        state.bookingDetail = newBooking.data;
      }
    },
    updateBooking(state, booking) {
      const index = state.bookingList.book.findIndex((book) => book.bookid === booking.data.bookid);
      if (index >= 0) {
        state.bookingList.book.splice(index, 1, booking.data);
        state.bookingList.book = [...state.bookingList.book];
      }
      state.bookingDetail = booking.data;
    },
    deleteBooking(state, bookID) {
      state.bookingList.book = state.bookingList.book.filter(book => book.bookid !== bookID);
    },
    setBookingLoadingSpinner(state, isLoading) {
      state.isLoadingBooking = isLoading;
    },
    setBookingDetailLoadingSpinner(state, isLoading) {
      state.isLoadingBookingDetail = isLoading;
    },
    setCurrentDate(state, date) {
      state.currentDate = date;
    },
    clearBookingList(state) {
      state.bookingList = [];
    },
    clearBookingDetail(state) {
      state.bookingDetail = {};
    },
    updatePendingBookingCount(state, count) {
      state.pendingBookingCount = count;
    },
    checkBookingAvailability(state, isAvailable) {
      state.bookingAvailability = isAvailable;
    },
    setBookingAvailabilityMsg(state, message) {
      state.bookingAvailabilityMsg = message;
    },
    setAvailabilityLoading(state, isLoading) {
      state.isLoadingAvailability = isLoading;
    },
    getMonthlyBooking(state, monthlyBooking) {
      state.monthlyBooking = monthlyBooking;
    },
    getBookingLog(state, bookingLogList) {
      state.bookingLogList = bookingLogList;
    },
    clearBookingLog(state) {
      state.bookingLogList = [];
    },
    highlightBooking(state, booking) {
      state.highlightBooking = booking.bookid;
    },
    scannedBooking(state, bookid) {
      state.scannedBooking = bookid;
    },
    getTotalBooking(state, total) {
      state.totalBooking = total;
    },
    getBookingSourceList(state, sourceList) {
      state.bookingSourceList = sourceList.data;
    },
    isSearchBookingPriority(state, isPriority){
      state.isSearchBookingPriority = isPriority;
    },
    setSearchParams(state, params){
      state.searchParams = params;
    }
  },
  actions: {
    async getBookingList(context, params = {}) {
      context.commit('setBookingLoadingSpinner', true);
      await bookingService.getBookingList(params).then((res) => {
        /* make sure first priority is search & only append result when search criteria appends 
         * this to avoid normal listing slower than search result that will override result
         */
        let isResultReady = false
        if(this.state.booking.isSearchBookingPriority == true){
          if(Object.keys(params).length !== 0)
            isResultReady = true
        }
        else
            isResultReady = true

        if(isResultReady){
          context.commit('getBookingList', res.data);
          if (res.data.data && res.data.data.book_pagination) {
              context.commit(
                'getTotalBooking',
                res.data.data.book_pagination ? res.data.data.book_pagination.total : 0
              );
          }
        }
        context.commit('setBookingLoadingSpinner', false);
      });
    },
    async getPendingBooking(context, params = { status: 2 }) {
      context.commit('setBookingLoadingSpinner', true);
      await bookingService.getBookingList(params).then((res) => {
        if (res.data.data && res.data.data.book_pagination) {
          context.commit(
            'updatePendingBookingCount',
            res.data.data.book_pagination ? res.data.data.book_pagination.total : 0
          );
        }

        context.commit('getBookingList', res.data);
        context.commit('setBookingLoadingSpinner', false);
      });
    },
    async getBookingParameter(context, params) {
      await bookingService.getBookingParameter(params).then((res) => {
        context.commit('getBookingParameter', res.data);
      });
    },
    async getBookingDetail(context, bookingID) {
      context.commit('setBookingDetailLoadingSpinner', true);
      context.commit('clearBookingDetail');
      await bookingService.getBookingDetail(bookingID).then((res) => {
        context.commit('getBookingDetail', res.data);
        context.commit('setBookingDetailLoadingSpinner', false);
        context.commit('highlightBooking', res.data.data);
      });
    },
    async createBooking(context, data) {
      const res = await bookingService.createBooking(data);
      context.commit('highlightBooking', res.data.data);
    },
    async updateBooking(context, data) {
      const res = await bookingService.updateBooking(data);
      context.commit('highlightBooking', res.data.data);
    },
    async deleteBooking(context, bookID) {
      await bookingService.deleteBooking(bookID).then(() => {
        context.commit('deleteBooking', bookID);
      });
    },
    async checkBookingAvailability(context, data) {
      context.commit('setAvailabilityLoading', true);
      await bookingService
        .checkBookingAvailability(data)
        .then((res) => {
          context.commit('checkBookingAvailability', true);
          context.commit('setBookingAvailabilityMsg', res.data.data);
          context.commit('setAvailabilityLoading', false);
        })
        .catch((error) => {
          context.commit('checkBookingAvailability', false);
          context.commit('setBookingAvailabilityMsg', error.response.data.errors.tour_status);
          context.commit('setAvailabilityLoading', false);
        });
    },
    // Special Booking
    async createSpecialBooking(context, data) {
      await bookingService.createSpecialBooking(data);
    },
    async getMonthlyBooking(context, params) {
      const res = await bookingService.getMonthlyBooking(params);
      context.commit('getMonthlyBooking', res.data);
    },
    async updateFOC(context, params) {
      await bookingService.updateFOC(params);
    },
    async getBookingLog(context, bookid) {
      const res = await bookingService.getBookingLog(bookid);
      context.commit('getBookingLog', res.data);
    },
    async getBookingSourceList(context, params) {
      const res = await bookingService.getBookingSourceList(params);
      context.commit('getBookingSourceList', res.data);
    },
    async generateBookingReport(context, params) {
      await bookingService.generateBookingReport(params).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'text/csv',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', params.name + '.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};

export default booking;
