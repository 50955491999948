<template>
  <div class="d-inline custom-checkbox">
    <i
      class="far"
      v-bind:class="{'fa-check-square text-yellow': isChecked, 'fa-square text-muted': !isChecked}"
      @click="isChecked = !isChecked"
    ></i>
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  i {
    font-size: 15px;
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>