<template>
  <div class="sidebar">
    <div class="brand"></div>
    <div class="branch row no-gutters" @click="toggleBranch()"
      v-bind:class="{ 'clickable': userData.branches && userData.branches.length > 1 }">
      <div class="col-10 offset-1">
        <span class="capitalize" v-if="userData && userData.branch_profile">
          {{ userData.branch_profile.branchname }}
        </span>
        Branch
      </div>
      <div class="col-1 text-right" v-if="userData.branches && userData.branches.length > 1">
        <font-awesome-icon class="custom-icon mb-0 custom-chevron" icon="chevron-right" />
      </div>
    </div>
    <div class="branch-select" v-bind:class="{ 'hide': !isToggleBranch }"
      v-if="userData.branches && userData.branches.length > 1">
      <ul>
        <li v-bind:key="branch.id" v-for="(branch) in userData.branches" @click="selectBranch(branch.id)">
          {{ branch.branchname }} ({{ branch.branchcode }})
        </li>
      </ul>
    </div>
    <perfect-scrollbar class="perfect-scrollbar-height">
      <ul>
        <li v-if="hasAccess('bookings')" v-bind:class="{ 'router-link-exact-active': hasRoute('/bookings') }">
          <div>
            <a v-b-toggle.collapse-1 href="javascript:void(0)" @click="toggle()">
              <div class="row no-gutters align-items-center">
                <div class="col-11">
                  <font-awesome-icon class="custom-icon mr-2" icon="calendar-check" />Bookings
                </div>
                <div class="col-1 text-right">
                  <font-awesome-icon class="custom-icon mb-0 custom-chevron" icon="chevron-right"
                    v-bind:class="{ 'rotate': isToggle }" />
                </div>
              </div>
            </a>
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <router-link to="/bookings/month">Monthly</router-link>
                <router-link to="/bookings/list"
                  v-bind:class="{ 'router-link-exact-active': hasRoute('/bookings/list') }"
                  class="mt-2">Daily</router-link>
                <router-link to="/bookings/search" class="mt-2">Search</router-link>
                <router-link to="/bookings/pending" class="mt-2">
                  Pending
                  <span class="notification" v-if="$store.state.booking.pendingBookingCount">{{
        $store.state.booking.pendingBookingCount }}</span>
                </router-link>
                <BookingFormModal :type="1" v-if="hasPermission('create_booking')" />
              </b-card>
            </b-collapse>
          </div>
        </li>
        <li v-if="hasAccess('check_in')" v-bind:class="{ 'router-link-exact-active': hasRoute('/check-in') }">
          <router-link to="/check-in">
            <font-awesome-icon class="custom-icon mr-2" icon="bicycle" />Check-in
          </router-link>
        </li>
        <li v-if="hasAccess('guides')" v-bind:class="{ 'router-link-exact-active': hasRoute('/guides') }">
          <router-link :to="'/guides/booking?date=' + currentDate"
            v-bind:class="{ 'router-link-exact-active': hasRoute('/guides') }">
            <font-awesome-icon class="custom-icon mr-2" icon="user-friends" />Guide Scheduling
          </router-link>
        </li>
        <li v-if="hasAccess('tours')">
          <router-link to="/tours" v-bind:class="{ 'router-link-exact-active': hasRoute('/tours/') }">
            <font-awesome-icon class="custom-icon mr-2" icon="flag" />All Tours
          </router-link>
        </li>
        <li v-if="hasAccess('staffs')" v-bind:class="{ 'router-link-exact-active': hasRoute('/staffs') }">
          <router-link to="/staffs/calendar" v-bind:class="{ 'router-link-exact-active': hasRoute('/staffs') }">
            <font-awesome-icon class="custom-icon mr-2" icon="address-card" />Staff
          </router-link>
        </li>
        <li v-if="hasAccess('agents')" v-bind:class="{ 'router-link-exact-active': hasRoute('/agents') }">
          <router-link to="/agents/list" v-bind:class="{ 'router-link-exact-active': hasRoute('/agents') }">
            <font-awesome-icon class="custom-icon mr-2" icon="handshake" />Agents
          </router-link>
        </li>
        <!-- Agent path -->
        <li v-if="hasAccess('agentBooking')">
          <router-link to="/agentBooking">
            <font-awesome-icon class="custom-icon mr-2" icon="calendar-check" />Booking
          </router-link>
        </li>

        <li v-if="hasAccess('agentProfile')">
          <router-link to="/agentProfile/0" v-bind:class="{ 'router-link-exact-active': hasRoute('/agentProfile') }">
            <font-awesome-icon class="custom-icon mr-2" icon="user" />My Profile
          </router-link>
        </li>

        <!-- //////// -->

        <li v-if="hasAccess('customers')" v-bind:class="{ 'router-link-exact-active': hasRoute('/customers') }">
          <router-link to="/customers">
            <font-awesome-icon class="custom-icon mr-2" icon="user" />Customers
          </router-link>
        </li>
        <li v-if="hasAccess('products')" v-bind:class="{ 'router-link-exact-active': hasRoute('/products') }">
          <div>
            <a v-b-toggle.collapse-product href="javascript:void(0)" @click="isToggleProduct = !isToggleProduct">
              <div class="row no-gutters align-items-center">
                <div class="col-11">
                  <font-awesome-icon class="custom-icon mr-2" icon="archive" />Products
                </div>
                <div class="col-1 text-right">
                  <font-awesome-icon class="custom-icon mb-0 custom-chevron" icon="chevron-right"
                    v-bind:class="{ 'rotate': isToggleProduct }" />
                </div>
              </div>
            </a>
            <b-collapse id="collapse-product" class="mt-2">
              <b-card>
                <router-link to="/products/tours">Tours</router-link>
                <router-link to="/products/simcards" class="mt-2">Extra's</router-link>
              </b-card>
            </b-collapse>
          </div>
        </li>

        <li v-if="hasAccess('promocodes')" v-bind:class="{ 'router-link-exact-active': hasRoute('/promocodes') }">
          <router-link to="/promocodes">
            <font-awesome-icon class="custom-icon mr-2" icon="star" />Promocodes
          </router-link>
        </li>

        <li v-if="hasAccess('branches')" v-bind:class="{ 'router-link-exact-active': hasRoute('/branches') }">
          <router-link to="/branches">
            <font-awesome-icon class="custom-icon mr-2" icon="share-alt" />Locations
          </router-link>
        </li>
        <li v-if="hasAccess('admin')" v-bind:class="{ 'router-link-exact-active': hasRoute('/admin') }">
          <router-link to="/admin">
            <font-awesome-icon class="custom-icon mr-2" icon="cog" />Admin
          </router-link>
        </li>
        <li v-if="!hasAccess('agentProfile')" v-bind:class="{ 'router-link-exact-active': hasRoute('/myProfile') }">
          <router-link to="/myProfile">
            <font-awesome-icon class="custom-icon mr-2" icon="user" />My Profile
          </router-link>
        </li>
      </ul>
    </perfect-scrollbar>
    <div class="user-profile">
      <a v-b-toggle.collapse-2 href="javascript:void(0)" class="fullname" @click="isToggleLogout = !isToggleLogout">{{
        fullname }}</a>
      <b-collapse id="collapse-2" class="mt-2 logout">
        <b-card>
          <a href="javascript:void(0)" @click="$refs.confirmModal.openModal()">Logout</a>
        </b-card>
      </b-collapse>
      <font-awesome-icon icon="chevron-down" class="custom-icon" v-bind:class="{ 'rotate': isToggleLogout }" />
      <span class="role">{{ currentRole }}</span>
    </div>
    <ApproveConfirmationModal :title="'Logout Confirmation'" :body="'Are you sure you want to logout?'"
      ref="confirmModal" :button="'Logout'" @submit="logout" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import USER_ROLE from '@/services/userRole';
import authService from '@/services/authenticationService';
import BookingFormModal from '@/components/bookings/BookingFormModal';
import ApproveConfirmationModal from '@/components/commons/ApproveConfirmationModal';
import Notification from '@/services/notificationService';
import Permission from '@/services/permissionService';

export default {
  name: 'Sidebar',
  components: {
    BookingFormModal,
    ApproveConfirmationModal,
  },
  data() {
    return {
      currentRole: localStorage.role,
      isToggle: false,
      currentDate: moment().format('YYYY-MM-DD'),
      userData: {},
      isToggleLogout: false,
      isToggleProduct: false,
      isToggleBranch: false,
      channel: null,
    };
  },
  computed: {
    fullname() {
      return localStorage.fullname;
    },
  },
  created() {
    authService.getUserDetail().then((res) => {
      const user = res.data.data;
      this.userData = user;
      this.channel = this.$pusher.subscribe(
        `CVK-${user.branch_profile ? user.branch_profile.branchcode : 'BKK'}`
      );
      this.channel.bind('createdBook', (data) => {
        this.$store.commit('createBooking', data);
      });
      this.channel.bind('updatedBook', (data) => {
        this.$store.commit('updateBooking', data);
      });
    }).catch((err) => {
      if (err?.response && err?.response?.status === 401) {
        localStorage.clear();
        delete axios.defaults.headers.common.authorization;
        this.$router.push('/login');
      }
    })

    if (this.hasAccess('bookings')) {
      this.getPendingBooking({ status: 2 });
    }
    this.getBookingParameter();
    this.getTourParameter();
  },
  methods: {
    ...mapActions([
      'getBookingParameter',
      'getPendingBooking',
      'getTourParameter',
      'switchBranch',
    ]),
    hasRoute(partial) {
      return this.$route.path.indexOf(partial) > -1;
    },
    logout() {
      authService
        .logout()
        .then(() => {
          localStorage.clear();
          delete axios.defaults.headers.common.authorization;
          this.$router.push('/login');
        })
        .catch((error) => {
          Notification.error(error);
        });
    },
    hasAccess(routeName) {
      const permission = USER_ROLE.find(
        (obj) => obj.role === localStorage.role
      );
      return permission.menu[routeName];
    },
    hasPermission(name) {
      return Permission.hasAccess(name);
    },
    toggle() {
      this.isToggle = !this.isToggle;
    },
    toggleBranch() {
      this.isToggleBranch = !this.isToggleBranch;
    },
    async selectBranch(branchId) {
      await this.switchBranch(branchId);
      this.toggleBranch();
      this.$router.go();
    },
  },
  destroyed() {
    if (this.channel) {
      this.channel.bind('createdBook');
      this.channel.bind('updatedBook');
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 125px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $dark-bg-color;
  z-index: 1;

  .card {
    border: none;
    background: transparent;

    .card-body {
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 22px;
    }
  }
}

.perfect-scrollbar-height {
  height: calc(100% - 220px);
  margin-top: 132px;
}

ul {
  padding-left: 0;
  list-style: none;

  // margin-top: 132px;
  // overflow: auto;
  // height: calc(100% - 220px);
  li {
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 12px;

    a {
      color: $white;
      display: flex;
      align-items: center;
      font-size: 13px;
    }
  }
}

.brand {
  background: url('../assets/images/logo.png');
  height: 83px;
  display: block;
  background-size: cover;
  background-position: top;
  margin-bottom: 15px;
  padding-top: 15px;
  display: block;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.15rem;
  padding-left: 10px;
  position: absolute;
  left: 0;
  width: 100%;
  top: -15px;
  padding: 20px;
  margin-bottom: 0;
  background-repeat: no-repeat;
}

svg.custom-icon {
  width: 15px;
  height: 15px;
  color: $white;
  transition: 300ms all;
}

li.router-link-exact-active {
  background: $light-black;
}

a.router-link-exact-active {
  color: $dark-yellow;
  font-weight: bold;

  svg.custom-icon {
    color: $dark-yellow;
  }
}

.branch {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 20px 10px 0;
  color: #ffffff;
  background: #000000;
  margin: auto;
  top: 69px;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.branch-select {
  color: #fff;
  position: absolute;
  top: 68px;
  background: #000;
  left: 125px;
  min-width: 170px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
}

.branch-select ul {
  margin: 0;
  border-bottom: 1px solid #000;
}

.branch-select li:hover {
  background-color: #393939;
}

.hide {
  display: none;
}

.user-profile {
  position: absolute;
  bottom: 0;
  background: #000000;
  width: 100%;
  padding: 15px 20px;
  left: 0;
  color: $white;

  a {
    color: $white;
  }

  .role {
    display: block;
    text-transform: capitalize;
    color: #a7a7a7;
    font-size: 0.9rem;
  }

  svg {
    position: absolute;
    top: 24px;
    right: 17px;
    transform: rotate(-90deg);
    width: 10px;
    height: 10px;

    &.rotate {
      transform: rotate(0deg) scale(0.9);
    }
  }

  .collapsed {
    line-height: 1.15rem;
    margin-bottom: 10px;
    display: block;
  }
}

.custom-chevron {
  transform: scale(0.8);
  padding-top: 5px;
}

.rotate {
  transform: rotate(90deg) scale(0.8);
}

.notification {
  margin-left: 5px;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  background: #f6e22e;
  color: #333333;
  text-align: center;
  font-size: 10px;
  white-space: nowrap;
}

.fullname {
  line-height: 1.15rem;
  display: block;
  max-width: 75px;
}

.logout {
  .card {
    .card-body {
      padding-left: 10px;

      a {
        font-size: 0.9rem;
        line-height: 1.15rem;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
</style>