import Axios from 'axios';
import moment from 'moment';
import API_URL from '@/services/api';

const utils = {
  validateNumber(value) {
    if (value > 100) {
      return (value = 100);
    }

    if (value < 0) {
      return (value = 1);
    }

    return value;
  },

  validatePrice(value) {
    if (value > 100000) {
      return (value = 100000);
    }

    if (value < 0) {
      return (value = 0);
    }

    return value;
  },
  validatePercent(value) {
    if (value > 100) {
      return (value = 100);
    }

    if (value < 0) {
      return (value = 0);
    }

    return value;
  },
  validateEmail(email) {
    return Axios.get(`${API_URL.check_email}`, { params: { email } });
  },
  validateDeduct(value, bookingPrice = 0) {
    const maxDeductPrice = Math.floor((bookingPrice * 25) / 100);
    if (localStorage.role.includes('staff')) {
      if (value > maxDeductPrice) {
        return (value = maxDeductPrice);
      }
  
      if (value < 0) {
        return (value = 0);
      }
  
      return value;
    } 

    return value;
  },
  checkPastBooking(bookingDate) {
    if (localStorage.role.includes('staff')) {
      const today = moment();
      const isPastBooking = moment(bookingDate).isBefore(today, 'date');
      return isPastBooking;
    }

    return false;
  },
};

export default utils;
