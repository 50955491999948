import branchService from '@/services/branchService';

const branch = {
  state: {
    branchList: [],
    branchDetail: {},
    contactList: [],
    branchID: null
  },
  mutations: {
    getBranchList(state, branchList) {
      state.branchList = branchList.data;
    },
    getBranchDetail(state, branch) {
      state.branchDetail = branch.data;
    },
    createBranch(state, newBranch) {
      state.branchList = [...state.branchList, newBranch.data];
    },
    createBranchContact(state, newContact) {
      state.contactList = [...state.contactList, newContact.data];
    },
    setBranchID(state, branchID) {
      state.branchID = branchID;
    }
  },
  actions: {
    async getBranchList(context, params = {}) {
      const res = await branchService.getBranchList(params);
      context.commit('getBranchList', res.data);
    },
    async getBranchDetail(context, branchID) {
      const res = await branchService.getBranchDetail(branchID);
      context.commit('getBranchDetail', res.data);
    },
    async createBranch(context, data) {
      const res = await branchService.createBranch(data);
      context.commit('createBranch', res.data);
    },
    async updateBranch(context, data) {
      await branchService.updateBranch(data);
    },
    async createBranchContact(context, data) {
      const res = await branchService.createBranchContact(data);
      context.commit('createBranchContact', res.data);
    },
    async updateBranchContact(context, data) {
      await branchService.updateBranchContact(data);
    },
    async switchBranch(context, branchID) {
      await branchService.switchBranch(branchID);
    }
  }
};

export default branch;
