<template>
  <div 
    v-bind:class="{show : show, hide: !show}">
    <button class="btn btn-outline-secondary btn-lg w-100" @click="$refs.uploadFile.click()">
      <font-awesome-icon icon="file-upload" class="mr-2"></font-awesome-icon>
      {{ contractFile ? contractName : placeholder }}
      <a
        href="javascript:void(0)"
        class="ml-2 text-yellow"
        @click="removeFile"
        v-if="contractFile"
      >
        <font-awesome-icon icon="times-circle"></font-awesome-icon>
      </a>
    </button>
    <input type="file" ref="uploadFile" hidden @input="getFile" accept="application/pdf, image/*" />
  </div>
</template>

<script>
export default {
  props: ['index','maxfiles'],
  data() {
    return {
      show: true,
      contractFile: null,
      contractName: '',
      placeholder: 'Upload File'
    };
  },
  mounted() {
    const shouldShow = this.index === undefined || this.index < this.maxfiles;
    this.show = shouldShow ? true : false;
  },
  methods: {
    getFile(e) {
      if (!e.target.files[0]) {
        this.clearInput();
        return;
      }
      this.contractFile = e.target.files[0];
      this.contractName = e.target.files[0].name;
      if(this.index !== undefined) {
        this.$emit('uploaded', this.contractFile, this.index);
      } else {
        this.$emit('uploaded', this.contractFile);
      }
    },
    removeFile(e) {
      e.stopPropagation();
      this.clearInput();
    },
    clearInput() {
      this.$refs.uploadFile.value = '';
      this.contractFile = null;
      this.contractName = '';
      if(this.index !== undefined) {
        this.$emit('removed', this.index);
      } else {
        this.$emit('removed');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.file-upload {
  text-align: center;
  padding: 10px;
  background: $light-grey;
  width: 100%;
  display: block;
  margin: 10px 0;
}
.btn-upload {
  background: $light-grey;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
</style>