<template>
  <div>
    <button class="btn btn-outline-secondary w-100" @click="isShow = !isShow">
      <font-awesome-icon icon="plus" class="mr-2"></font-awesome-icon>Surcharge
    </button>
    <b-modal v-model="isShow" :hide-footer="true" title="Surcharge" centered>
      <div class="form-group row">
        <label class="col-form-label col-3">Amount</label>
        <div class="col-9">
          <div class="input-group no-border">
            <div class="input-group-prepend">
              <span class="input-group-text">THB</span>
            </div>
            <input
              type="number"
              class="form-control text-right"
              v-model="amount"
            />
          </div>
          <small class="text-red" v-if="error.amount">Amount is required</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3">Remark*</label>
        <div class="col-9">
          <input type="text" class="form-control" v-model="remark" />
          <small class="text-red" v-if="error.remark">Remark is required</small>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" @click="submit">Add</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
export default {
  data() {
    return {
      isShow: false,
      amount: "",
      remark: "",
      error: {},
    };
  },
  props: {
    combineHistoryAndAmountList: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    validateInput() {
      this.amount === 0
        ? (this.error["amount"] = true)
        : delete this.error["amount"];
      this.remark ? delete this.error["remark"] : (this.error["remark"] = true);
      return this.error;
    },
    submit() {
      this.error = {};
      if (!isEmpty(this.validateInput())) {
        return;
      }

      this.$emit("addSurcharge", {
        type: "Surcharge",
        amount: Number(this.amount),
        remark: this.remark,
      });
      this.isShow = false;
      this.amount = "";
      this.remark = "";
      this.$emit("totalNet", this.combineHistoryAndAmountList);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>