const PRODUCT_ROUTING = [
  {
    path: 'tours',
    name: 'product-tours',
    component: () => import('@/views/products/ProductList.vue')
  },
  {
    path: 'simcards',
    name: 'product-simcards',
    component: () => import('@/views/products/SimcardList.vue')
  },
];

export default PRODUCT_ROUTING;
