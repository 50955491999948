const BOOKING_ROUTING = [
  {
    path: 'list',
    name: 'booking-list',
    component: () => import('@/views/bookings/BookingList.vue')
  },
  {
    path: 'month',
    name: 'booking-month',
    component: () => import('@/views/bookings/BookingMonth.vue')
  },
  {
    path: 'search',
    name: 'booking-search',
    component: () => import('@/views/bookings/BookingSearch.vue')
  },
  {
    path: 'pending',
    name: 'booking-pending',
    component: () => import('@/views/bookings/BookingPending.vue')
  }
];

export default BOOKING_ROUTING;
