import Axios from 'axios';
import API_URL from '@/services/api';

const guideService = {
  getGuideList: (params = {}) => {
    return Axios.get(`${API_URL.guide_list}`, { params });
  },
  getGuideTeamList: (params = {}) => {
    const reformattedParams = Object.assign({}, params);
    delete params.tourgroup_id;
    return Axios.get(`${API_URL.guide_parameters}/${reformattedParams.tourgroup_id}`, {
      params: params,
    });
  },
  getStandbyGuideList: (params) => {
    return Axios.get(`${API_URL.guide_parameters}`, { params });
  },
  createGuideTeam: (data) => {
    return Axios.post(`${API_URL.guide_list}`, data);
  },
  updateGuideTeam: (data) => {
    let url = API_URL . guide_list;
    if (data.tourgroup_id) {
      url = `${url}/${data.tourgroup_id}`;
    }
    return Axios.post(url, data);
  },
  deleteGuideTeam: (data) => {
    return Axios.delete(
      `${API_URL.guide_list}/${data.tourgroup_id}?number=${data.team}&date=${data.date}`
    );
  },
  updateGuideColorTeam: (data) => {
    return Axios.post(`${API_URL.guide_list}/team/${data.id || data.get('id')}`, data);
  },
};

export default guideService;
