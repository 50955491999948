var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table summary-price-table"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"width":"70%"}},[_vm._v("Price")]),_c('td',{attrs:{"width":"5%"}},[_vm._v("THB")]),_c('td',{staticClass:"text-right",attrs:{"width":"25%"}},[_c('span',{staticClass:"h5 text-black"},[_vm._v(_vm._s(_vm._f("number")(_vm.total_amount)))])])]),_vm._l((_vm.combineHistoryAndAmountList),function(ext,index){return _c('tr',{key:index,class:{
        'd-none':
          (ext.type === 'Changes' && _vm.calculatePax(ext)) ||
          (ext.type === 'Sim' && ext.amount === 0) ||
          ext.type === 'bill',
        excluded:
          ext.type === 'change' ||
          (ext.type === 'Changes' && !_vm.calculatePax(ext)),
        refund: ext.type === 'refund',
      }},[_c('td',[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(ext.type))]),(ext.type !== 'bill')?_c('p',{staticClass:"mb-0"},[_vm._v(" » "+_vm._s(ext.remark)+" ")]):_vm._e()]),_c('td',[_vm._v("THB")]),_c('td',{staticClass:"text-right no-wrap"},[_c('span',{staticClass:"h5 text-black"},[_vm._v(" "+_vm._s(_vm._f("number")(ext.amount))+" ")])])])}),(_vm.combineHistoryAndAmountList.length)?_c('tr',{staticClass:"total-payment"},[_c('td',[_vm._v("Balance")]),_c('td',[_vm._v("THB")]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"h5 text-black"},[_c('span',{class:{
              'text-red': _vm.total_net > 0,
              'text-green': _vm.total_net < 0,
            },attrs:{"id":"priceSummeryAmount"}},[_vm._v(_vm._s(_vm._f("number")(_vm.reverse_total_net)))])])])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }