import tourService from "@/services/tourService"

const tour = {
  state: {
    tourList: [],
    parameterList: {},
    tourDetail: {},
    branchTourDetail: {},
    tourSummary: [],
    tourGroupList: [],
    tourTeamList: [],
    groupColorParameterList: {},
    isLoadingTour: true,
    isLoadingTourSummary: true,
    isLoadingTourDetail: true,
    isLoadingTourGroup: true,
    tourID: null,
    timeID: 1,
    allTourList: [],
    groupColorDetail: {},
    excludedTourList: [],
    excludedTourDetail: {},
  },
  mutations: {
    getTourList(state, tourList) {
      state.tourList = tourList.data
    },
    getTourParameter(state, parameterList) {
      state.parameterList = parameterList.data
    },
    getTourDetail(state, tour) {
      state.tourDetail = tour.data
    },
    getBranchTourDetail(state, tour) {
      state.branchTourDetail = tour.data
    },
    getTourSummary(state, tourSummary) {
      state.tourSummary = tourSummary.data
    },
    getTourGroupList(state, tourGroupList) {
      state.tourGroupList = tourGroupList.data
    },
    getGroupColorParameter(state, parameterList) {
      state.groupColorParameterList = parameterList.data
    },
    createTour(state, newTour) {
      state.tourList = newTour.data.concat(state.tourList)
    },
    updateTour(state, updatedTour) {
      const tourIndex = state.tourList.findIndex(
        (tour) => tour.id === updatedTour.data.id
      )
      const cloneTourList = Object.assign([], state.tourList)
      cloneTourList[tourIndex] = updatedTour.data
      state.tourList = cloneTourList
    },
    setTourLoadingSpinner(state, isLoading) {
      state.isLoadingTour = isLoading
    },
    setTourSummaryLoadingSpinner(state, isLoading) {
      state.isLoadingTourSummary = isLoading
    },
    setTourGroupLoadingSpinner(state, isLoading) {
      state.isLoadingTourGroup = isLoading
    },
    setTourDetailLoadingSpinner(state, isLoading) {
      state.isLoadingTourDetail = isLoading
    },
    setTourID(state, tourID) {
      state.tourID = tourID
    },
    setTimeID(state, timeID) {
      state.timeID = timeID
    },
    clearTourDetail(state) {
      state.tourDetail = {}
    },
    clearTourList(state) {
      state.tourList = []
    },
    getTourTeamList(state, tourTeamList) {
      state.tourTeamList = tourTeamList.data
    },
    createTourTeam(state, newTeam) {
      state.tourTeamList = [...state.tourTeamList, newTeam.data]
    },
    getAllTourList(state, allTourList) {
      state.allTourList = allTourList.data
    },
    getColorGroupDetail(state, groupDetail) {
      state.groupColorDetail = groupDetail.data
    },
    getExcludedTourList(state, excludedTourList) {
      state.excludedTourList = excludedTourList
    },
  },
  actions: {
    async getTourList(context, params = {}) {
      context.commit("setTourLoadingSpinner", true)
      const res = await tourService.getTourList(params)
      context.commit("getTourList", res.data)
      context.commit("setTourLoadingSpinner", false)
    },
    async getTourParameter(context, params = {}) {
      const res = await tourService.getTourParameter(params)
      context.commit("getTourParameter", res.data)
    },
    async getTourDetail(context, tourID) {
      context.commit("setTourDetailLoadingSpinner", true)
      const res = await tourService.getTourDetail(tourID)
      context.commit("getTourDetail", res.data)
      context.commit("setTourDetailLoadingSpinner", false)
    },
    async getBranchTourDetail(context, params) {
      const res = await tourService.getBranchTourDetail(params)
      context.commit("getBranchTourDetail", res.data)
    },
    async getTourSummary(context, params = {}) {
      context.commit("setTourSummaryLoadingSpinner", true)
      const res = await tourService.getTourSummary(params)
      context.commit("getTourSummary", res.data)
      context.commit("setTourSummaryLoadingSpinner", false)
    },
    async getTourGroupList(context, params = {}) {
      context.commit("setTourGroupLoadingSpinner", true)
      const res = await tourService.getTourSummary(params)
      context.commit("getTourGroupList", res.data)
      context.commit("setTourGroupLoadingSpinner", false)
    },
    async createTour(context, data) {
      await tourService.createTour(data)
    },
    async updateTour(context, data) {
      await tourService.updateTour(data)
    },
    async updateTourCapacity(context, data) {
      await tourService.updateTourCapacity(data)
    },
    async getTourTeamList(context, params = {}) {
      const res = await tourService.getTourTeamList(params)
      context.commit("getTourTeamList", res.data)
    },
    async createTourTeam(context, data) {
      await tourService.createTourTeam(data)
    },
    async updateTourTeam(context, data) {
      return await tourService.updateTourTeam(data)
    },
    async assignBookingToTeam(context, data) {
      await tourService.assignBookingToTeam(data)
    },
    async departTour(context, data) {
      await tourService.departTour(data)
    },
    async getGroupColorParameter(context, params = {}) {
      const res = await tourService.getGroupParameters(params)
      context.commit("getGroupColorParameter", res.data)
    },
    async getAllTourList(context, params = {}) {
      const res = await tourService.getAllTourList(params)
      context.commit("getAllTourList", res.data)
    },
    async getColorGroupDetail(context, groupID) {
      const res = await tourService.getColorGroupDetail(groupID)
      context.commit("getColorGroupDetail", res.data)
    },
    async updateTourGroup(context, groupID) {
      await tourService.updateTourGroup(groupID)
    },
    async deleteTourFile(context, data) {
      await tourService.deleteTourFile(data)
    },
    async approveTour(context, data) {
      await tourService.approveTour(data)
    },
    async suspendTour(context, data) {
      await tourService.suspendTour(data)
    },
    async generateTourExpenseReport(context, params) {
      await tourService.generateTourExpenseReport(params).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv",
          })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", params.name + ".csv")
        document.body.appendChild(link)
        link.click()
      })
    },
    // Excluded Tour
    async getExcludedTourList(context, params) {
      const res = await tourService.getExcludedTourList(params)
      context.commit("getExcludedTourList", res.data)
    },
    async clearExcludedTourList(context) {
      context.commit("getExcludedTourList", [])
    },
    async createExcludedTour(context, data) {
      await tourService.createExcludedTour(data)
    },
    async deleteExcludedTour(context, excludedTourID) {
      await tourService.deleteExcludedTour(excludedTourID)
    },
  },
}

export default tour
