import promotionService from '@/services/promotionService';

const booking = {
  state: {
    promotionList: []
  },
  mutations: {
    getPromotionList(state, promotionList) {
      state.promotionList = promotionList.data;
    },
    createPromotion(state, promotion) {
      state.promotionList = [...state.promotionList, promotion.data];
    },
    deletePromotion(state, promotionID) {
      state.promotionList = state.promotionList.filter(promo => promo.id !== promotionID);
    },
    updatePromotion(state, promotion) {
      const index = state.promotionList.findIndex(promo => promo.id === promotion.data.id);
      if (index >= 0) {
        state.promotionList.splice(index, 1, promotion.data);
        state.promotionList = [...state.promotionList];
      }
    }
  },
  actions: {
    async getPromotionList(context, params) {
      const res = await promotionService.getPromotionList(params);
      context.commit('getPromotionList', res.data);
    },
    async createPromotion(context, data) {
      const res = await promotionService.createPromotion(data);
      context.commit('createPromotion', res.data);
    },
    async updatePromotion(context, data) {
      const res = await promotionService.updatePromotion(data);
      context.commit('updatePromotion', res.data);
    },
    async deletePromotion(context, id) {
      await promotionService.deletePromotion(id);
      context.commit('deletePromotion', id);
    }
  }
};

export default booking;
