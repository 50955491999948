import { render, staticRenderFns } from "./BookingDeductModal.vue?vue&type=template&id=ff6e3c84&scoped=true&"
import script from "./BookingDeductModal.vue?vue&type=script&lang=js&"
export * from "./BookingDeductModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff6e3c84",
  null
  
)

export default component.exports