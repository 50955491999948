import agentService from '@/services/agentService';

const agent = {
  state: {
    agentList: [],
    parameterList: [],
    agentDetail: {},
    contactList: [],
    bookingList: []
  },
  mutations: {
    getAgentList(state, agentList) {
      state.agentList = agentList.data;
    },
    getAgentBookingList(state, bookingList) {
      state.bookingList = bookingList.data;
    },
    getAgentParameters(state, parameterList) {
      state.parameterList = parameterList.data;
    },
    getAgentDetail(state, agentDetail) {
      state.agentDetail = agentDetail.data;
    },
    createAgent(state, newAgent) {
      state.agentList = [...state.agentList, newAgent.data];
    },
    createAgentContact(state, newContact) {
      state.contactList = [...state.contactList, newContact.data];
    }
  },
  actions: {
    async getAgentList(context, params) {
      await agentService.getAgentList(params).then(res => {
        context.commit('getAgentList', res.data);
      });
    },
    async getAgentParameters(context, params) {
      await agentService.getAgentParameters(params).then(res => {
        context.commit('getAgentParameters', res.data);
      });
    },
    async getAgentDetail(context, agentID) {
      await agentService.getAgentDetail(agentID).then(res => {
        context.commit('getAgentDetail', res.data);
      });
    },
    async createAgent(context, data) {
      await agentService.createAgent(data).then(res => {
        context.commit('createAgent', res.data);
      });
    },
    async updateAgent(context, data) {
      await agentService.updateAgent(data);
    },
    async createAgentContact(context, data) {
      await agentService.createAgentContact(data).then(res => {
        context.commit('createAgentContact', res.data);
      });
    },
    async updateAgentContact(context, data) {
      await agentService.updateAgentContact(data);
    },
    async getAgentBookingList(context, params) {
      await agentService.getAgentBookingList(params).then(res => {
        context.commit('getAgentBookingList', res.data);
      });
    },
    async createAgentBooking(context, data) {
      await agentService.createAgentBooking(data);
    }
  }
};

export default agent;
