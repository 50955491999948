import Axios from 'axios';
import API_URL from '@/services/api';

const agentService = {
  getAgentList: (params = {}) => {
    return Axios.get(`${API_URL.user_list}/agent`, { params });
  },
  getAgentParameters: (params = {}) => {
    return Axios.get(`${API_URL.user_parameter}/agent`, { params });
  },
  getAgentDetail: agentID => {
    return Axios.get(`${API_URL.user_parameter}/agent/${agentID}`);
  },
  createAgent: (data = {}) => {
    return Axios.post(`${API_URL.user_list}/agent`, data);
  },
  updateAgent: (data = {}) => {
    return Axios.post(`${API_URL.user_list}/agent/${data.get('id')}`, data);
  },
  createAgentContact: data => {
    return Axios.post(`${API_URL.user_list}/agent-contact`, data);
  },
  updateAgentContact: data => {
    return Axios.post(`${API_URL.user_list}/agent-contact/${data.get('id')}`, data);
  },
  getAgentBookingList: params => {
    return Axios.get(`${API_URL.agent_list}`, { params });
  },
  createAgentBooking: data => {
    return Axios.post(`${API_URL.agent_list}`, data);
  }
};

export default agentService;
