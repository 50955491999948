import Axios from 'axios';
import API_URL from '@/services/api';

const tourService = {
  getTourList: (params = {}) => {
    return Axios.get(`${API_URL.tour_list}`, { params });
  },
  getTourParameter: (params = {}) => {
    return Axios.get(`${API_URL.tour_parameter}`, { params });
  },
  getTourDetail: (tourID) => {
    return Axios.get(`${API_URL.tour_parameter}/${tourID}`);
  },
  getBranchTourDetail: (params) => {
    return Axios.get(`${API_URL.tour_parameter}/${params.tour_id}`, { params });
  },
  getTourSummary: (params = {}) => {
    return Axios.get(`${API_URL.tour_summary}`, { params });
  },
  createTour: (data) => {
    return Axios.post(`${API_URL.tour_list}`, data);
  },
  updateTour: (data) => {
    return Axios.post(`${API_URL.tour_list}/${data.get('id')}`, data);
  },
  updateTourCapacity: (data) => {
    return Axios.post(`${API_URL.tour_list}/capacity/${data.get('id')}`, data);
  },
  getTourTeamList: (params) => {
    return Axios.get(`${API_URL.group_color_list}`, { params });
  },
  createTourTeam: (data) => {
    return Axios.post(`${API_URL.group_color_list}`, data);
  },
  updateTourTeam: (data) => {
    return Axios.post(`${API_URL.group_color_list}/${data.get('id')}`, data);
  },
  assignBookingToTeam: (data) => {
    return Axios.post(`${API_URL.checkin_list}/${data.get('bookid')}`, data);
  },
  departTour: (data) => {
    return Axios.post(`${API_URL.group_color_list}/departure/${data.get('groupcolor_id')}`, data);
  },
  getGroupParameters: (params) => {
    return Axios.get(`${API_URL.group_color_parameters}`, { params });
  },
  getAllTourList: (params) => {
    return Axios.get(`${API_URL.alltour_list}`, { params });
  },
  getColorGroupDetail: (groupID) => {
    return Axios.get(`${API_URL.alltour_parameter}/${groupID}`);
  },
  updateTourGroup: (data) => {
    return Axios.post(`${API_URL.alltour_list}/${data.get('id')}`, data);
  },
  deleteTourFile: (data) => {
    return Axios.post(`${API_URL.alltour_list}/${data.get('id')}/deletefile`, data);
  },
  approveTour: (data) => {
    return Axios.post(`${API_URL.tour_list}/${data.get('id')}/approve`, data);
  },
  suspendTour: (data) => {
    return Axios.post(`${API_URL.tour_list}/${data.get('id')}/suspend`, data);
  },
  // Report
  generateTourExpenseReport: (params) => {
    return Axios.get(
      `${API_URL.alltour_list}/report`,
      { params },
      { headers: { responseType: 'blob' } }
    );
  },
  // Excluded Tour
  getExcludedTourList: (params) => {
    return Axios.get(`${API_URL.tour}/${params.tour_id}/excluded-dates`, { params });
  },
  createExcludedTour: (data) => {
    return Axios.post(`${API_URL.tour}/${data.get('tour_id')}/excluded-dates`, data);
  },
  deleteExcludedTour: (excludedTourID) => {
    return Axios.delete(`${API_URL.tour}/excluded-dates/${excludedTourID}`);
  }
};

export default tourService;
