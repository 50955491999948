import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import STAFF_ROUTING from './staff-routing';
import AGENT_ROUTING from './agent-routing';
import BOOKING_ROUTING from './booking-routing';
import GUIDE_ROUTING from './guide-routing';
import PRODUCT_ROUTING from './product-routing';
import USER_ROLE from '@/services/userRole';
import Notification from '@/services/notificationService';

Vue.use(VueRouter);

const permission = USER_ROLE.find(obj => obj.role === localStorage.role);

const routes = [
  { path: '/', redirect: permission ? permission.landing_page : '/login' },
  {
    path: '/',
    name: '',
    component: Main,
    beforeEnter: (to, from, next) => {
      if (localStorage.jwtToken) {
        const pathMatched = to.matched[1].path.replace('-', '_').replace('/', '');
        const firstRouteName = pathMatched.split('/')[0];
        if (permission && permission.menu[firstRouteName]) {
          next();
        } else {
          Notification.customError('No permission to access');
          next(permission.landing_page);
        }
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '/bookings',
        name: 'bookings',
        component: () => import('@/views/bookings/Booking.vue'),
        children: BOOKING_ROUTING
      },
      {
        path: '/check-in',
        name: 'check-in',
        component: () => import('@/views/check-in/CheckInList.vue')
      },
      {
        path: '/guides',
        name: 'guides',
        component: () => import('@/views/guides/Guide.vue'),
        children: GUIDE_ROUTING
      },
      {
        path: '/tours',
        name: 'tours',
        component: () => import('@/views/tours/TourList.vue')
      },
      {
        path: '/staffs',
        name: 'staffs',
        component: () => import('@/views/staffs/Staff.vue'),
        children: STAFF_ROUTING
      },
      {
        path: '/staffs/:id',
        name: 'staff-detail',
        component: () => import('@/views/staffs/StaffDetail.vue')
      },
      {
        path: '/agents',
        name: 'agents',
        component: () => import('@/views/agents/Agent.vue'),
        children: AGENT_ROUTING
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/customers/CustomerList.vue')
      },
      {
        path: '/products',
        name: 'products',
        component: () => import('@/views/products/Product.vue'),
        children: PRODUCT_ROUTING
      },
      {
        path: '/promocodes',
        name: 'promocodes',
        component: () => import('@/views/promocodes/PromocodeList.vue')
      },
      {
        path: '/branches',
        name: 'branches',
        component: () => import('@/views/branches/Branch.vue')
      },
      {
        path: '/admin',
        component: () => import('@/views/admin/AdminList.vue')
      },
      // Agent Path
      {
        path: 'agentProfile/:id',
        name: 'agent-profile',
        component: () => import('@/components/agents/UserAgentProfile.vue')
      },
      {
        path: 'agentBooking',
        name: 'agent-booking',
        component: () => import('@/components/agents/UserAgentBooking.vue')
      },
      {
        path: 'myProfile',
        name: 'my-profile',
        component: () => import('@/views/staffs/MyStaffProfile.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
